import * as React from "react";
import { useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import { useFormikContext } from "formik";
import OrderSumContent from "../components/OrderSumContent";
// fullName, tel, email, dateFrom, dateTo, transport, totalSum, items, receiver

const orderSum = () => {
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const { values }: any = useFormikContext();

  return (
    <>
      {isSendingEmails && (
        <Paper
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: "9999",
          }}
        >
          <CircularProgress />
        </Paper>
      )}
      <OrderSumContent
        setIsSendingEmails={setIsSendingEmails}
        isSendingEmails={isSendingEmails}
        values={values}
      />
    </>
  );
};

export default orderSum;
