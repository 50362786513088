import * as React from "react";
import { useContext, useEffect } from "react";
import { Box, Divider, Stack, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CartContext from "../../context/CartContext";
import { useFormikContext } from "formik";
import { navigate } from "gatsby";

const orderSumTexts = {
  alert1: "V souhrnu není započítána doprava a práce.",
  alert2: "Cena je pouze orientační.",
  firstSectionHeader: "Základní údaje",
  secondSectionHeader: "Položky",
  date: "Datum rezervace",
  contact: "Kontaktní údaje",
  address: "Doprava",
  emptyCart: "Máte prázdný košík, přesměrování na hlavní stranku...",
};

const OrderSum = () => {
  const { cartContent } = useContext(CartContext);
  const formContext = useFormikContext();
  const startDate = formContext.values.dateFrom;
  const endDate = formContext.values.dateTo;
  const dateTimeFormat = new Intl.DateTimeFormat("cz", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  useEffect(() => {
    if (formContext.values.fullName === "") {
      setTimeout(() => navigate("/"), 3000);
    }
  }, [formContext]);
  if (formContext.values.fullName === "") {
    return (
      <Paper
        sx={{
          backgroundColor: "#FFF",
          p: 2,
        }}
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {orderSumTexts.emptyCart}
        </Typography>
      </Paper>
    );
  }
  return (
    <Stack
      direction="column"
      spacing={2.5}
      sx={{
        mb: "80px",
      }}
    >
      <Paper>
        <Stack
          direction="row"
          spacing={1.5}
          sx={{
            p: 2,
          }}
        >
          <ErrorIcon color="primary" fontSize="large" />
          <Typography variant="body2" sx={{ color: "grey.300" }}>
            {orderSumTexts.alert1}
            <br />
            {orderSumTexts.alert2}
          </Typography>
        </Stack>
      </Paper>
      <>
        <Typography variant="h6" sx={{ pl: 1.5 }}>
          {orderSumTexts.firstSectionHeader}
        </Typography>
        <Paper sx={{ pt: 1.5, pb: 2.25, px: 1.5 }}>
          <Box>
            <Typography sx={{ fontWeight: 700 }}>
              {orderSumTexts.date}
            </Typography>
            <Typography variant="body2">
              {dateTimeFormat.formatRange(startDate, endDate)}
            </Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>
              {" "}
              {orderSumTexts.contact}
            </Typography>
            <Typography variant="body2">
              {formContext.values.fullName}
            </Typography>
            <Typography variant="body2">{formContext.values.tel}</Typography>
            <Typography variant="body2">{formContext.values.email}</Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>
              {orderSumTexts.address}
            </Typography>
            <Typography variant="body2">
              {formContext.values.transport === "Potřebuji dopravu"
                ? formContext.values.address
                : "Odvezu si sám"}
            </Typography>
          </Box>
        </Paper>
      </>
      <>
        <Typography variant="h6" sx={{ pl: 1.5 }}>
          {orderSumTexts.secondSectionHeader}
        </Typography>
        <Stack direction="column" spacing={1.5}>
          {cartContent.map((item) => (
            <>
              <Paper
                sx={{
                  p: 1.5,
                }}
                key={item.name}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography sx={{ color: "grey.300" }} variant="body2">
                  {item.note}
                </Typography>
                <Divider
                  orientation="horizontal"
                  sx={{
                    my: 1.5,
                  }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2">{item.count} ks</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: "CZK",
                      maximumFractionDigits: 0,
                    }).format(item.pricePerOne * item.count)}
                  </Typography>
                </Stack>
              </Paper>
            </>
          ))}
        </Stack>
      </>
    </Stack>
  );
};

export default OrderSum;
