import * as React from "react";
import { Container, Drawer, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import CartContext from "../context/CartContext";
import { Button } from "gatsby-theme-material-ui";

const CartFoot = (props: {
  buttonText: string;
  buttonUrl?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { cartSum, isSomeItems } = useContext(CartContext);

  return (
    <Drawer anchor="bottom" variant="permanent" open={isSomeItems}>
      <Container
        sx={{
          width: "100%",
          background: "#FFF",
          padding: 1.5,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{
            px: 2,
          }}
        >
          <Stack direction="column" spacing={0}>
            <Typography variant="body2">Součet</Typography>
            <Typography variant="subtitle1">
              <strong>
                {new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: "CZK",
                  maximumFractionDigits: 0,
                }).format(cartSum)}
              </strong>
            </Typography>
          </Stack>
          <Button
            size="large"
            disableElevation
            to={props.buttonUrl}
            form="contactForm"
            type="submit"
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        </Stack>
      </Container>
    </Drawer>
  );
};

export default CartFoot;
