import OrderPhaseIndicator from "../Order/OrderPhaseIndicator";
import OrderSum from "../Order/OrderSum";
import CartFoot from "../CartFoot";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Container } from "@mui/material";
import emailjs from "@emailjs/browser";
import { navigate } from "gatsby";
import CartContext from "../../context/CartContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from "@mui/material/styles";

const OrderSumContent = ({ setIsSendingEmails, isSendingEmails, values }) => {
  const theme = useTheme();
  const [captchaValue, setCaptchaValue] = useState();
  const { cartSum, cartContent, clearCart } = useContext(CartContext);
  const emailContentListString = () => {
    return (
      "<ul>" +
      cartContent.map(
        (item) =>
          `<li><b>${item.name}</b><br/>${new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: "CZK",
            maximumFractionDigits: 0,
          }).format(item.pricePerOne * item.count)} / ${item.count}ks<br/>${
            item.note
          }</li>`
      ) +
      "</ul>"
    );
  };

  const emailTemplateParamsWithoutReceiver = {
    fullName: values.fullName,
    tel: values.tel,
    email: values.email,
    dateFrom: Intl.DateTimeFormat("cs-CZ").format(values.dateFrom),
    dateTo: Intl.DateTimeFormat("cs-CZ").format(values.dateTo),
    transport:
      values.transport === "Odvezu si sám" ? "Vlastní" : values.address,
    totalSum: Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: "CZK",
      maximumFractionDigits: 0,
    }).format(cartSum),
    items: emailContentListString(),
  };

  const sendEmailToClient = async (token) => {
    return emailjs.send(
      `service_gw6b2er`,
      `template_hb6h6kb`,
      {
        receiver: values.email,
        "g-recaptcha-response": token,
        ...emailTemplateParamsWithoutReceiver,
      },
      "IAXON0N8z7hiLL6Kj"
    );
  };

  // async function sendEmailToPronajmiSiTo(token) {
  //   return emailjs.send(
  //     `service_gw6b2er`,
  //     `template_8uqgdrf`,
  //     {
  //       receiver: "info@pronajmisito.cz",
  //       "g-recaptcha-response": token,
  //       ...emailTemplateParamsWithoutReceiver,
  //     },
  //     "IAXON0N8z7hiLL6Kj"
  //   );
  // }

  function onChange(value) {
    setCaptchaValue(value);
  }

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const token = captchaValue;

    if (token) {
      //setToken(result); //--> grab the generated token by the reCAPTCHA
      setIsSendingEmails(true);
      // first we send it to us, after that we send it to the client
      // const pronEmail = sendEmailToPronajmiSiTo(token);
      const clientEmail = sendEmailToClient(token);
      await Promise.all([/*pronEmail,*/ clientEmail]);
      setIsSendingEmails(false);
      clearCart();
      navigate("/thank-you");
    } else {
      alert("Please, confirm that you are not a robot ");
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <OrderPhaseIndicator phase={2} />
      <ReCAPTCHA
        sitekey="6LdmQsQpAAAAAOS4ZyurQoRa4Cv198ehyX62jq5Q"
        onChange={onChange}
        style={{
          marginBottom: theme.spacing(2.5),
        }}
      />
      <OrderSum />
      {!isSendingEmails && values.fullName && (
        <CartFoot buttonText={"Potvrdit"} onClick={handleClick} />
      )}
    </Container>
  );
};

export default OrderSumContent;
