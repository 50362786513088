import { Stack, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as React from "react";

interface IOrderPhaseIndicatorProps {
  phase: number;
}

const OrderPhaseIndicator = (props: IOrderPhaseIndicatorProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        my: 3,
        color: "grey.200",
      }}
    >
      <Typography
        sx={{
          fontWeight: props.phase == 0 ? "bold" : "",
          color: props.phase == 0 ? "text.primary" : "grey.200",
        }}
      >
        Košík
      </Typography>
      <NavigateNextIcon />
      <Typography
        sx={{
          fontWeight: props.phase == 1 ? "bold" : "",
          color: props.phase == 1 ? "text.primary" : "grey.200",
        }}
      >
        Kontakt
      </Typography>
      <NavigateNextIcon />
      <Typography
        sx={{
          fontWeight: props.phase == 2 ? "bold" : "",
          color: props.phase == 2 ? "text.primary" : "grey.200",
        }}
      >
        Souhrn
      </Typography>
    </Stack>
  );
};

export default OrderPhaseIndicator;
